@import "vars";

body {
    font-family: $font;
    font-weight: 300;
    font-size: 16px;
    background: $bg-colour;
    color: $primary;
    letter-spacing: 1px;
}
audio,
canvas,
iframe,
img,
svg,
video {
    vertical-align: middle;
    max-width: 100%;
}
img {
    height:auto !important;
}
h1, h2, h3, h4 {
    text-transform: uppercase;
}
h1, h2, h3 {
    letter-spacing: 2px;
}

.clear-float {
    clear:both;
}

.highlight {
    color: $secondary;
}

.button {
    display: inline-block;
    vertical-align: middle;
    text-align: center;
    text-decoration: none;
    font-weight: bold;
    text-transform: uppercase;
    padding: 10px 30px;
    max-width: 100%;
    color: $primary;
    background-color: transparent;
    border: 1px solid $primary;
    box-sizing: border-box;
    transition: color 0.3s ease, background-color 0.3s ease, border-color 0.3s ease;
    &:hover {
        background-color: $secondary;
        border-color: $secondary;
    }
}

@media only screen and (max-width: 300px){
    .button {
        padding: 10px;
        width: 100%;
    }
}